<template>
  <main>
    <package-header title="Validation Summary" sub-title="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2">

          <!-- Package navigation hamburger -->
          <button class="btn btn-secondary d-lg-none mb-2" data-toggle="collapse" data-target="#package-nav" aria-expanded="false" aria-controls="package-nav">
            <svg class="icon icon-menu"><use xlink:href="/icons/symbol-defs.svg#icon-menu"></use></svg>
            <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col-lg-8">

          <p class="lead">This is a summary of any errors or warnings that appeared in your package. You cannot submit a package with <span class="text-danger">Errors</span>, but you can still submit with <span class="text-warning">Warnings</span>.</p>

          <div class="card mt-4">
            <div class="card-body bg-light">
              <div class="d-md-flex justify-content-between mb-2">
                <h2 class="mb-1">Package</h2>
                <small>Last validated: <strong>Jan 29, 2018 11:34 AM</strong></small>
              </div>
              <p>Validate your package to confirm it is ready for submission through the Web Filing portal.</p>
              <button class="btn btn-tertiary">Validate Package</button>
              <ul class="list-unstyled mt-2">
                <li>Errors: <strong>1</strong></li>
                <li>Warning: <strong>0</strong></li>
              </ul>
              <small>Note: Applications are validated separately and are not included in Package Validation.</small>
            </div>
            <ul class="list-group list-group-flush mb-0">
              <li class="list-group-item d-flex justify-content-between">
                <div>
                  <svg class="icon icon-error text-danger"><use xlink:href="/icons/symbol-defs.svg#icon-error"></use></svg>
                  The package sequence in Package Management is incorrect. [12345]
                </div>
                <a href="javascript:void(0)">Fix</a>
              </li>
            </ul>
          </div>

          <div class="card mt-5">
            <div class="card-body bg-light border-bottom">
              <h2>Applications</h2>
              <ul class="list-unstyled mb-0">
                <li class="lead">Total Documents: <strong>3</strong></li>
                <li>Documents with errors: <strong>2</strong></li>
                <li>Documents with warnings only: <strong>1</strong></li>
              </ul>
            </div>

            <div class="card-header bg-light">
              <div class="d-md-flex align-items-center justify-content-between">
                <h4 class="mb-0"><a href="javascript:void(0)">01 - Application of Release</a></h4>
                <small>Last modified: Jan 16, 2018 4:35 PM</small>
              </div>
            </div>

            <div class="card-body">
              <h5 class="mb-0">Execution</h5>
            </div>

            <ul class="list-group list-group-flush mb-0">
              <li class="list-group-item d-flex justify-content-between">
                <div>
                  <svg class="icon icon-error text-danger"><use xlink:href="/icons/symbol-defs.svg#icon-error"></use></svg> Charge number/document Reference BG275906 is either invalid or inactive. [12345]
                </div>
                <a href="javascript:void(0)">Fix</a>
              </li>
              <li class="list-group-item d-flex justify-content-between">
                <div>
                  <svg class="icon icon-warning text-warning"><use xlink:href="/icons/symbol-defs.svg#icon-warning"></use></svg> The address for an officer is in the incorrect format. [12345]
                </div>
                <a href="javascript:void(0)">Fix</a>
              </li>
            </ul>

            <div class="card-body">
              <h5 class="mb-0">Transferor</h5>
            </div>

            <ul class="list-group list-group-flush mb-0">
              <li class="list-group-item d-flex justify-content-between">
                <div>
                  <svg class="icon icon-warning text-warning"><use xlink:href="/icons/symbol-defs.svg#icon-warning"></use></svg> The first name of the Individual Transferor field cannot be left blank.  [12345]
                </div>
                <a href="javascript:void(0)">Fix</a>
              </li>
            </ul>

            <div class="card-header bg-light">
              <div class="d-md-flex align-items-center justify-content-between">
                <h4 class="mb-0"><a href="javascript:void(0)">02 - Application of Charge</a></h4>
                <small>Last modified: Jan 28, 2018 11:14 AM</small>
              </div>
            </div>
            <div class="card-body">
              <h5 class="mb-0">Attachments</h5>
            </div>
            <ul class="list-group list-group-flush mb-0">
              <li class="list-group-item d-flex justify-content-between">
                <div>
                  <svg class="icon icon-error text-danger"><use xlink:href="/icons/symbol-defs.svg#icon-error"></use></svg> [Error message]. [12345]
                </div>
                <a href="javascript:void(0)">Fix</a>
              </li>
            </ul>

            <div class="card-header bg-light">
              <div class="d-md-flex justify-content-between">
                <h4 class="mb-0"><a href="javascript:void(0)">03 - Application Name</a></h4>
                <small>Last modified: Jan 28, 2018 11:14 AM</small>
              </div>
            </div>
            <ul class="list-group list-group-flush mb-0">
              <li class="list-group-item">
                <svg class="icon icon-check text-success"><use xlink:href="/icons/symbol-defs.svg#icon-check"></use></svg> No Errors Found
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Package Validation"></help-panel>
  </main>

</template>

<script>

import PackageHeader from '../../components/PackageHeader.vue'
import PackageNavigation from '../../components/PackageNavigation.vue'
import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel
  },

  data () {
    return {
      refreshSummary: false
    }
  },
  methods: {
    refresh: function() {
      this.refreshSummary = true

      setTimeout(() => {
        this.refreshSummary = false
      }, 2000)
    }
  }
}
</script>

